import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Sidebar from "../components/sidebar"
import CornerColor from "../components/cornerColor"
import Layout from "../components/layout"
import Nav from "../components/nav"
import Seo from "../components/seo"
import Menu from "../components/hamburgerMenu"

import * as projectsStyles from "../styles/modules/projects.module.scss"

import Spotify from "../svg/spotify.svg"

const ProjectsPage = () => {
  return (
    <>
      <div className={`${"gridContainer"} ${projectsStyles.gridContainer}`}>
        <Sidebar propStyle={projectsStyles.sidebar___projects} />
        <CornerColor pageStyle={projectsStyles.corner} />
        <header>
          <Nav
            propActiveStyle={projectsStyles.activeLink}
            propLinkStyle={projectsStyles.navLink}
          />
          <Menu />
        </header>
        <Layout
          layoutContainerStyle={projectsStyles.layoutContainer}
          footerTopStyle={projectsStyles.footerTop}
        >
          <Seo title="Projects" />
          <h2>Projects</h2>
          <div className={projectsStyles.center}>
            <a
              href="https://open.spotify.com/artist/1NpeO0rYdDwUjtaXMDlb25"
              className={projectsStyles.spotifyLink}
              target="_blank"
              rel="noreferrer noopener"
            >
              <Spotify className={projectsStyles.spotify} />
            </a>
          </div>
          {/*
            <div className={projectsStyles.center}>
            <a
            className={projectsStyles.allmusicLink}
            href="https://www.allmusic.com/artist/rob-moose-mn0000563132/credits"
            target="_blank"
            rel="noreferrer noopener"
            >
            See all discography
            </a>
            </div>
          */}
          <div className={projectsStyles.albumGridContainer}>
            <section className={projectsStyles.grid}>
              <figure>
                <StaticImage
                  className={projectsStyles.img}
                  src="../images/bonIver_ii.png"
                  alt="Bon Iver cover i,i"
                />
                <figcaption>Bon Iver: I,I</figcaption>
              </figure>
              <figure>
                <StaticImage
                  className={projectsStyles.img}
                  src="../images/bonIver_22aMillion.png"
                  alt="Bon Iver cover Bon Iver"
                />
                <figcaption>Bon Iver: 22, A Million</figcaption>
              </figure>
              <figure>
                <StaticImage
                  className={projectsStyles.img}
                  src="../images/bonIver_bonIver.png"
                  alt="Bon Iver cover 22, a million"
                />
                <figcaption>Bon Iver: Bon Iver</figcaption>
              </figure>
              <figure>
                <StaticImage
                  className={projectsStyles.img}
                  src="../images/phoebeBridgers_copycatKiller.png"
                  alt="Phoebe Bridgers: Copycat Killer"
                />
                <figcaption>Phoebe Bridgers: Copycat Killer</figcaption>
              </figure>
              <figure>
                <StaticImage
                  className={projectsStyles.img}
                  src="../images/phoebeBridgers_punisher.png"
                  alt="Phoebe Bridgers: Punisher"
                />
                <figcaption>Phoebe Bridgers: Punisher</figcaption>
              </figure>
              <figure>
                <StaticImage
                  className={projectsStyles.img}
                  src="../images/phoebeBridgers_strangerInTheAlps.png"
                  alt="Phoebe Bridgers: Stranger in the Alps"
                />
                <figcaption>Phoebe Bridgers: Stranger in the Alps</figcaption>
              </figure>
              <figure>
                <StaticImage
                  className={projectsStyles.img}
                  src="../images/taylorSwift_folklore.png"
                  alt="Taylor Swift: Folklore"
                />
                <figcaption>Taylor Swift: Folklore</figcaption>
              </figure>
              <figure>
                <StaticImage
                  className={projectsStyles.img}
                  src="../images/saraBareilles_moreLove.png"
                  alt="Sara Bareilles: More Love"
                />
                <figcaption>Sara Bareilles: More Love</figcaption>
              </figure>
              <figure>
                <StaticImage
                  className={projectsStyles.img}
                  src="../images/johnLegend_darknessAndLight.png"
                  alt="John Legend: Darkness and Light"
                />
                <figcaption>John Legend: Darkness and Light</figcaption>
              </figure>
              <figure>
                <StaticImage
                  className={projectsStyles.img}
                  src="../images/fkaTwigs_caprisongs.png"
                  alt="FKA Twigs: Caprisongs"
                />
                <figcaption>FKA Twigs: Caprisongs</figcaption>
              </figure>
              <figure>
                <StaticImage
                  className={projectsStyles.img}
                  src="../images/alabamaShakes_soundAndColor.png"
                  alt="Alabama Shakes: Sound and Color"
                />
                <figcaption>Alabama Shakes: Sound and Color</figcaption>
              </figure>
              <figure>
                <StaticImage
                  className={projectsStyles.img}
                  src="../images/mosesSumney_grae.png"
                  alt="Moses Sumney: Græ"
                />
                <figcaption>Moses Sumney: Græ</figcaption>
              </figure>
              <figure>
                <StaticImage
                  className={projectsStyles.img}
                  src="../images/marenMorris_girl.png"
                  alt="Maren Morris: Girl"
                />
                <figcaption>Maren Morris: Girl</figcaption>
              </figure>
              <figure>
                <StaticImage
                  className={projectsStyles.img}
                  src="../images/haim_womenInMusic.png"
                  alt="Haim: Women in Music pt III"
                />
                <figcaption>Haim: Womem in Music pt III</figcaption>
              </figure>
              <figure>
                <StaticImage
                  className={projectsStyles.img}
                  src="../images/lauraMarling_songForOurDaughter.png"
                  alt="Laura Marling: Song For Our Daughter"
                />
                <figcaption>Laura Marling: Song For Our Daughter</figcaption>
              </figure>
              <figure>
                <StaticImage
                  className={projectsStyles.img}
                  src="../images/paulSimon_inTheBlueLight.png"
                  alt="Paul Simon: In the Blue Light"
                />
                <figcaption>Paul Simon: In the Blue Light</figcaption>
              </figure>
              <figure>
                <StaticImage
                  className={projectsStyles.img}
                  src="../images/bruceHornsby_absoluteZero.png"
                  alt="Bruce Hornsby: Absolute Zero"
                />
                <figcaption>Bruce Hornsby: Absolute Zero</figcaption>
              </figure>
              <figure>
                <StaticImage
                  className={projectsStyles.img}
                  src="../images/theKillers_pressureMachine.png"
                  alt="The Killers: Pressure Machine"
                />
                <figcaption>The Killers: Pressure Machine</figcaption>
              </figure>
              <figure>
                <StaticImage
                  className={projectsStyles.img}
                  src="../images/everythingEverywhereAllAtOnce.png"
                  alt="Everything Everywhere All At Once"
                />
                <figcaption>Everything Everywhere All At Once</figcaption>
              </figure>
              <figure>
                <StaticImage
                  className={projectsStyles.img}
                  src="../images/benBohmer_theApparitions.png"
                  alt="Ben Bohmer: The Apparitions"
                />
                <figcaption>Ben Bohmer: The Apparitions</figcaption>
              </figure>
              <figure>
                <StaticImage
                  className={projectsStyles.img}
                  src="../images/motheringSunday.png"
                  alt="Mothering Sunday: Original Motion Picture Soundtrack"
                />
                <figcaption>Mothering Sunday</figcaption>
              </figure>
            </section>
          </div>
          <div className={projectsStyles.videoContainer}>
            <iframe
              className={projectsStyles.video}
              title="Video - Rob Moose w/ Sara Bareilles at Madison Square"
              width="560"
              height="315"
              src="https://www.youtube-nocookie.com/embed/3hd-Es-Bh4Y?rel=0"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              loading="lazy"
              srcDoc="<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=https://www.youtube-nocookie.com/embed/3hd-Es-Bh4Y?rel=0><img src=https://img.youtube.com/vi/3hd-Es-Bh4Y/hqdefault.jpg alt='Video - Rob Moose w/ Sara Bareilles at Madison Square'><span>▶</span></a>"
            />
            <iframe
              className={projectsStyles.video}
              title="Rob Moose w/ Phoebe Bridgers at NPR Tiny Desk Concert"
              width="560"
              height="315"
              src="https://www.youtube-nocookie.com/embed/-hLJNZSIwP8?rel=0"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              loading="lazy"
              srcDoc="<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=https://www.youtube-nocookie.com/embed/-hLJNZSIwP8?rel=0><img src=https://img.youtube.com/vi/-hLJNZSIwP8/hqdefault.jpg alt='Rob Moose w/ Phoebe Bridgers at NPR Tiny Desk Concert'><span>▶</span></a>"
            />
            {/* 
          <iframe
            className={projectsStyles.video}
            title="Rob Moose w/ Punch Brothers at Telluride Bluegrass Festival"
            width="560"
            height="315"
            src="https://www.youtube-nocookie.com/embed/JEeeoManQtY?rel=0"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            loading="lazy"
            srcDoc="<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=https://www.youtube-nocookie.com/embed/JEeeoManQtY?rel=0><img src=https://img.youtube.com/vi/JEeeoManQtY/hqdefault.jpg alt='Rob Moose w/ Punch Brothers at Telluride Bluegrass Festival'><span>▶</span></a>"
          />
          */}
            <iframe
              className={projectsStyles.video}
              title="Rob Moose w/ Kesha at Billboard Music Awards"
              width="560"
              height="315"
              src="https://www.youtube-nocookie.com/embed/SnwbXbvur4g?rel=0"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              loading="lazy"
              srcDoc="<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=https://www.youtube-nocookie.com/embed/SnwbXbvur4g?rel=0><img src=https://img.youtube.com/vi/SnwbXbvur4g/hqdefault.jpg alt='Rob Moose w/ Kesha at Billboard Music Awards'><span>▶</span></a>"
            />
            <iframe
              className={projectsStyles.video}
              title="Rob Moose w/ Regina Spektor at The Kennedy Center"
              width="560"
              height="315"
              src="https://www.youtube-nocookie.com/embed/2U_I073jZu0?rel=0"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              loading="lazy"
              srcDoc="<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=https://www.youtube-nocookie.com/embed/2U_I073jZu0?rel=0><img src=https://img.youtube.com/vi/2U_I073jZu0/hqdefault.jpg alt='Rob Moose w/ Regina Spektor at The Kennedy Center'><span>▶</span></a>"
            />
          </div>
        </Layout>
      </div>
    </>
  )
}

export default ProjectsPage
