// extracted by mini-css-extract-plugin
export var gridContainer = "projects-module--gridContainer--VMEYN";
export var layoutContainer = "projects-module--layoutContainer--eHE0t";
export var link = "projects-module--link--UCepy";
export var sidebar___projects = "projects-module--sidebar___projects--IwnMJ";
export var corner = "projects-module--corner--DWD12";
export var navLink = "projects-module--navLink--RfFcD";
export var activeLink = "projects-module--activeLink--y+Cad";
export var projectsOutline = "projects-module--projectsOutline--Nv20G";
export var center = "projects-module--center--vSJin";
export var spotify = "projects-module--spotify--z1KaI";
export var spotifyLink = "projects-module--spotifyLink--DOk0C";
export var videoContainer = "projects-module--videoContainer--pBwx4";
export var video = "projects-module--video--Np9EK";
export var albumGridContainer = "projects-module--albumGridContainer--AJnx-";
export var grid = "projects-module--grid--ktkdF";
export var img = "projects-module--img--4PCOB";
export var allmusicLink = "projects-module--allmusicLink--Dab0N";
export var footerTop = "projects-module--footerTop--rBWNw";
export var aboutOutline = "projects-module--aboutOutline--SHejN";
export var contactOutline = "projects-module--contactOutline--Ih6Zc";